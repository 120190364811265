import React, { useContext } from "react"
import { parseBlockContent } from "helpers/parseBlockContent"
import { PreviewContext } from "context/PreviewContext"
import { Content, EmailSubscription } from "UI"
import { InternalTemplateContainer } from "./style"
import { LatestResources } from "components/Project/LatestResources"
import { RelatedPages } from "components/Project/RelatedPages"
import { MainColumn, Spacer } from "UI"

const InternalTemplate = ({ data }) => {
  const { content } = data

  const { previewData } = useContext(PreviewContext)

  const parsedContent =
    previewData?.templateData?.content || content
      ? parseBlockContent(content)
      : null

  const pageSettingsData =
    previewData?.templateData?.pageSettings || data.pageSettings

  return (
    <InternalTemplateContainer>
      <MainColumn>
        <Content>{parsedContent}</Content>
        <Spacer height={[null, 30, 45]} />
      </MainColumn>

      <EmailSubscription />
      <RelatedPages pageSettingsData={pageSettingsData} />
      <LatestResources />
    </InternalTemplateContainer>
  )
}

export default InternalTemplate
